<script>
import api from "../store/api";
import { formatTicketDateTime } from "@/store/utils/utils.js";

export default {
  mounted() {
    this.getCenters();
    this.getUsers();
  },
  methods: {
    getCenters() {
      const additionalCenters =
        this.$store.state.Company.company.additionalCenters || [];
      this.centers = [
        ...this.$store.state.Company.company.centers,
        ...additionalCenters,
      ];
    },
    getCenterName(centerId) {
      let center = this.centers.find((center) => center.center_id === centerId);
      return center ? center.center_name : "";
    },
    async getUsers() {
      this.users = this.$store.state.User.companyUsers;
    },
    getUserName(userId) {
      if (this.users.length === 0) {
        this.getUsers();
      }
      let user = this.users.find((user) => user.user_id === userId);
      return user ? user.name : "";
    },
    formatTicketDateTime(dateString) {
      return formatTicketDateTime(dateString);
    },
  },
};
</script>
