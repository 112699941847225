var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "v-card",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
          attrs: { elevation: "0", width: "100%" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pr-0" },
            [
              _c(
                "v-row",
                { class: _vm.$vuetify.breakpoint.smAndDown ? "mt-3" : "" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-subtitle-1 font-weight-bold my-auto",
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "ml-1",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("suspendTickets")) + " "),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2 pa-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            pill: "",
                            small: "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tickets.length ? _vm.tickets.length : 0
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      class: !_vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-0 pr-4"
                        : "pr-6",
                      attrs: { cols: "12", lg: "4", md: "4", xl: "4" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "rounded-lg",
                        attrs: {
                          "prepend-inner-icon": "mdi-magnify",
                          label: _vm.$t("search"),
                          "single-line": "",
                          "hide-details": "",
                          flat: "",
                          solo: "",
                          "background-color": "grey lighten-4",
                          "clear-icon": "mdi-close-circle",
                          clearable: "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.tickets,
              search: _vm.search,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.start_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatTicketDateTime(item.start_date)) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.disposition",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: _vm.getColor(item.disposition),
                            small: "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.getStatus(item.disposition)) + " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.options.requestObject.center_id",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getCenterName(
                              item.options.requestObject.center_id
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.editRow",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            plain: "",
                            small: "",
                            disabled: item.disposition !== "R",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editItem(item)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }