<template>
  <div class="mt-4">
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-card-title class="pr-0">
        <v-row :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''">
          <span
            class="text-subtitle-1 font-weight-bold my-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-1'"
            >{{ $t("suspendTickets") }}
            <v-chip class="ma-2 pa-2" color="primary" outlined pill small>
              {{ tickets.length ? tickets.length : 0 }}
            </v-chip>
          </span>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
              clear-icon="mdi-close-circle"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table :headers="headers" :items="tickets" :search="search">
        <template v-slot:[`item.start_date`]="{ item }">
          <div class="nowrap">
            {{ formatTicketDateTime(item.start_date) }}
          </div>
        </template>
        <template v-slot:[`item.disposition`]="{ item }">
          <v-chip :color="getColor(item.disposition)" small class="white--text">
            {{ getStatus(item.disposition) }}
          </v-chip>
        </template>

        <template v-slot:[`item.options.requestObject.center_id`]="{ item }">
          {{ getCenterName(item.options.requestObject.center_id) }}
        </template>
        <template v-slot:[`item.editRow`]="{ item }">
          <v-btn
            plain
            small
            :disabled="item.disposition !== 'R'"
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil-outline</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import TicketData from "@/mixins/TicketData";
import EditTicket from "@/mixins/EditTicket";
import api from "../../../store/api";
export default {
  mixins: [TicketData, EditTicket],
  data() {
    return {
      search: "",
      tickets: [],

      headers: [
        { text: this.$t("approver"), value: "options.requestObject.center_id" },
        { text: this.$t("disposition"), align: "start", value: "disposition" },
        { text: this.$t("comments"), align: "start", value: "comments" },
        { text: this.$t("ticket"), align: "start", value: "center_ticket_id" },
        { text: this.$t("priority"), align: "start", value: "priority" },
        { text: this.$t("type"), value: "type" },
        { text: this.$t("address"), value: "street_address" },
        { text: this.$t("place"), value: "place" },
        { text: this.$t("county"), value: "county" },
        { text: this.$t("state"), value: "state" },
        {
          text: "Edit",
          value: "editRow",
          width: "100",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getSuspends();
  },
  methods: {
    async getSuspends() {
      let response = await api.ticket.getSuspends();
      this.tickets = response;

      for (let ticket of this.tickets) {
        ticket.user_name = this.getUserName(ticket.user_id);
        ticket.center_name = this.getCenterName(ticket.center_id);
      }
    },
    getStatus(status) {
      if (status == null) {
        return "Pending";
      }
      if (status == "R") {
        return "Rejected";
      }
    },
    getColor(status) {
      let _status = this.getStatus(status);
      if (_status.toLowerCase() === "pending") return "#C35309";
      else if (_status.toLowerCase() === "rejected") return "red";
      else return "#1E853A";
    },
    getCenterName(centerId) {
      let center = this.centers.find((center) => center.center_id === centerId);
      return center ? center.center_name : "";
    },
    async editItem(item) {
      let requestObject = item.options.requestObject;
      let ticket = requestObject.data;
      let element = {
        options: {
          geoJson: null,
          excavationDetails: null,
          ticketDetails: null,
        },
      };

      let geoJson = {
        type: "FeatureCollection",
        features: [],
      };
      geoJson.features.push(ticket.shape);

      // excavation details
      // Handle details for each center
      let excavationDetails = {};
      let ticketDetails = {};
      let center = this.getCenterName(requestObject.center_id);
      // Julie
      if (center === "JULIE") {
        excavationDetails = {
          work_date: ticket.work_date || "",
          contact: ticket.contact || "",
          contact_phone: ticket.contact_phone || "",
          work_type: ticket.work_type || "",
          subdivision: ticket.subdivision || "",
          done_for: ticket.done_for || "",
          extent: ticket.extent || "",
          location: ticket.location || "",
          comments: ticket.comments || "",
          boring: ticket.boring == "Y" ? true : false,
          depth_7ft: ticket.depth_7ft == "Y" ? true : false,
          survey1: ticket.survey1 == "Y" ? true : false,
          survey2: ticket.survey2 == "YES" ? true : false,
          typeOfWorkTextBox: ticket.typeOfWorkTextBox || "",
          excavationAreaTextBox: ticket.excavationAreaTextBox || "",
        };
        ticketDetails = {
          callerType: ticket.callerType || "",
          county: ticket.county || "",
          place: ticket.place || "",
          state: ticket.state || "",
          street: ticket.street + ", " + ticket.place || "",
          states: this.getStates || [],
          places: ticket.places || [],
          counties: ticket.counties || [],
          autoSuggestAddress: ticket.autoSuggestAddress || [],
          center: this.getCenterName(requestObject.center_id) || "",
          center_id: requestObject.center_id || "",
          streetSearchResults: ticket.streetSearchResults || [],
        };
      }
      element.options.geoJson = geoJson;
      element.options.excavationDetails = excavationDetails;
      element.options.ticketData = ticketDetails;

      await this.editTicket(element, "editSuspends");
      this.$emit("createTicket");
      this.$router.push("/createTicket").catch();
    },
    getStates() {
      let states = [];
      // get centers from store
      let centers = this.centers;
      // loop through centers and get states
      for (let center of centers) {
        if (!states.includes(center.state)) {
          states.push(center.state);
        }
      }
      return states;
    },
  },
};
</script>

<style></style>
